import HeaderHome from "../blocks/header"
import Products from "../blocks/products"
import Offers from "../blocks/offers"
import Sliderhome from "../blocks/slider"
import Reservation from "../blocks/reservation"
import {Component} from 'react'

class HomePage extends Component
{
    
    componentDidMount()
    {
        document.title='Home | Food 4 you'
    }
    render()
    {
        return(
            <>
            <HeaderHome navOnepage={this.props.navOnepage}/>
            <Products  navOnepage={this.props.navOnepage}/>
            <Sliderhome/>
            <Offers/>
            <Reservation/>
            </>
        )
    }
}

export default HomePage