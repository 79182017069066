import sliderUno from '../../assets/img/imagen_slider_uno.jpg'
import sliderDos from '../../assets/img/slider1.jpg'
import sliderTres from '../../assets/img/slider2.jpg'
import sliderCuatro from '../../assets/img/slider3.jpg'
import sliderCinco from '../../assets/img/slider4.jpg'
import CircleIcon from '../../assets/svg/circulo';
import {Component} from 'react';
import $ from 'jquery'

const listSlider=[
    {
        image:sliderDos,
        alt:''
    },
    {
        image:sliderUno,
        alt:''
    },
    {
        image:sliderTres,
        alt:''
    }
    ,
    {
        image:sliderCuatro,
        alt:''
    }
    ,
    {
        image:sliderCinco,
        alt:''
    }
];

const configSlider=[
    'imgAnt',
    'imgActual',
    'imgSig'
]


let len=listSlider.length
let sliderState=true;

const markPoint=(k)=>
{
    if(k===-1)k=len-1
    if(k===0)k=len
    $('.point').removeClass('select')
    $('.point_'+(k-1)).addClass('select')
}
const time=500;

const changeSlider=(elem,key)=>
{   
    
    if($(elem).parent().hasClass('imgActual') ||  !sliderState)return
    sliderState=false;
    if($(elem).parent().hasClass('imgSig'))
    {
        if(key===len-1)key=-1
        markPoint(key)
        if($(window).width()>=600)
        {
            $('.imgAnt').animate({'left':'-25%','right':'auto'},time)
            $('.imgActual').animate({'height':'22vw','top':'3.5vw','left':'0','width':'25%'},time);
            $('.imgSig').animate({'height':'29vw','right':'25%','top':'0','width':'50%'},time)
            $('.img_'+(key+1)).css({'right':'-25%','display':'block'},time)
            $('.img_'+(key+1)).animate({'right':'0'},time)
        }
        else 
        {
            $('.imgAnt').animate({'left':'-35%','right':'auto'},time)
            $('.imgActual').animate({'height':'30vw','top':'80vw','left':'5%','width':'30%'},time);
            $('.imgSig').animate({'height':'70vw','right':'0','top':'0','width':'100%'},time)
            $('.img_'+(key+1)).css({'right':'-35%','display':'block'},time)
            $('.img_'+(key+1)).animate({'right':'5%'},time)
        }
        setTimeout(function(){
            let temp=$('.imgActual')
            temp.removeClass('imgActual')
            $('.imgAnt').addClass('hidden')
            $('.imgAnt').removeClass('imgAnt')
            $('.imgSig').addClass('imgActual')
            $('.imgSig').removeClass('imgSig')
            temp.addClass('imgAnt')
            $('.img_'+(key+1)).removeClass('hidden');
            $('.img_'+(key+1)).addClass('imgSig');
            $('.imgAnt').attr('style','')
            $('.imgSig').attr('style','')
            $('.imgActual').attr('style','')
            $('.hidden').attr('style','');            
            sliderState=true;

        },time)
    }
    else 
    {
        if(key===0)key=len
        markPoint(key)
        if($(window).width()>=600)
        {
            $('.imgSig').animate({'right':'-25%','left':'auto'},time)
            $('.imgActual').animate({'height':'22vw','top':'3.5vw','right':'0','left':'75%','width':'25%'},time);
            $('.imgAnt').animate({'height':'29vw','left':'25%','top':'0','width':'50%'},time)
            $('.img_'+(key-1)).css({'left':'-25%','display':'block'},time)
            $('.img_'+(key-1)).animate({'left':'0'},time);
        }
        else
        {
            $('.imgSig').animate({'right':'-35%','left':'auto'},time)
            $('.imgActual').animate({'height':'30vw','top':'80vw','right':'auto','left':'65%','width':'30%'},time);
            $('.imgAnt').animate({'height':'70vw','left':'0%','top':'0','width':'100%'},time)
            $('.img_'+(key-1)).css({'left':'-35%','display':'block'},time)
            $('.img_'+(key-1)).animate({'left':'5%'},time)

        }




        setTimeout(function(){
            let temp=$('.imgActual')
            temp.removeClass('imgActual')
            $('.imgSig').addClass('hidden')
            $('.imgSig').removeClass('imgSig')
            $('.imgAnt').addClass('imgActual')
            $('.imgAnt').removeClass('imgAnt')
            temp.addClass('imgSig')
            $('.img_'+(key-1)).removeClass('hidden');
            $('.img_'+(key-1)).addClass('imgAnt');
            $('.imgAnt').attr('style','')
            $('.imgSig').attr('style','')
            $('.imgActual').attr('style','')
            $('.hidden').attr('style','');          
            sliderState=true;

        },time)
    }
}


class Sliderhome extends Component
{
    render()
    {
        return (
            <>
                <section id="slider_home">
                    <strong>DISCOVER</strong>
                    <h2>OUR VARIETY OF DISHES</h2>
                    <p>Catering for all tastes including vegetarian, vegan and gluten fee. Also a special menu is available for our younger diners.</p>
                    <div className="slider">
                        {
                            listSlider.map((v,k)=>{
                                    return(
                                        <div key={k} onClick={(e)=>{changeSlider(e.target,k)}} 
                                        className={`${configSlider[k]} ${k>2? 'hidden' : ''} img_${k}`}>
                                            <img src={v.image} alt={v.alt}/>
                                        </div>
                                    )
                            })
                        }
                        
                       
                    </div>
                    <div className="clear"></div>
                    <div className="puntos">
                        {
                            listSlider.map((v,k)=>{
                                return(
                                    <i key={k}  className={`point ${k===0 ? 'select' :''} point_${k}`}>
                                        <CircleIcon/>
                                    </i>
                                )
                            })
                        }
                    </div>
                </section>
    
            </>
        )
    }
}

export default Sliderhome