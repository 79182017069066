import {Component} from 'react'
import HeaderMenu from '../blocks/header-menu'
import MenuPart from '../blocks/menu-part'
import '../../assets/css/menu.css'
import menuData from '../../api/menu.json'

class Menu extends Component
{
    componentDidMount()
    {
        document.title='Our menu | Food 4 you'
    }
    render()
    {
        return (
            <>
                <HeaderMenu/>
                <div className="menu-container">
                    {
                        menuData.map((data,k)=>{
                            return <MenuPart key={k} menuData={data}/>
                        })
                    }
                </div>
                
            </>
        )
    }
}

export default Menu