
import logoFooter from '../assets/img/logo_footer.png'
import EmailIcon from '../assets/svg/correo'
import UbicacionIcon from '../assets/svg/ubicacion'
import FbIcon from '../assets/svg/facebook'
import InstIcon from '../assets/svg/instagram'
import TwitterIcon from '../assets/svg/twitter'
import WspIcon from '../assets/svg/whatsapp'
import MadeBy from './made-by'



const MainFooter=props=>
{
    return (
        <footer id="main_footer">
            <div className="logo">
                <img src={logoFooter} alt="Logo footer"/>
            </div>
            <p className="texto">
            An authentic wood-fired oven giving a delicious smoked flavour. The only way to eat a pizza.
            All our ingredients are always fresh and either locally sourced or authentic italian.
            Our restaurants consist of chefs and cooks with years of experience in fine Italian cuisine..
            </p>
            <div className="datos">
                <span>
                        <i>
                            <UbicacionIcon/>
                        </i>
                        <p>Unit 3, 2 Goswell Hill, Windsor SL4 1RH</p>
                        <div className="clear"></div>
                </span>
                <span>
                        <i>
                            <EmailIcon/>
                        </i>
                        <p>contact@food4you.com</p>
                        <div className="clear"></div>
                </span>
            </div>
            <div className="redes">
                <i>
                    <InstIcon/>
                </i>
                <i>
                    <FbIcon/>
                </i>
                <i>
                    <TwitterIcon/>
                </i>
                <i>
                    <WspIcon/>
                </i>
            </div>
            <MadeBy/>
        </footer>

    )
}

export default MainFooter