import {Component} from 'react'

export default class MenuPart extends Component 
{
    render()
    {
        const path='https://www.food4you.sebastianneumann.fr/img/'
        const d=this.props.menuData;
        return(
            <>
                <div className={`menu-module ${d.direction}`}>
                    <div className="menu-module-img">
                        <h3>{d.name}</h3>
                        <img src={path+d.image} alt=""/>
                    </div>
                    <div className="menu-module-content">
                        <ul>
                            {
                                d.plates.map((v,k)=>{
                                    return (
                                        <li key={k}>
                                            {v}
                                        </li>
                                        )
                                })
                            }
                        </ul>
                        {/* <button className="btn1">Reserve now</button> */}
                    </div>
                    <div className="menu-module-bg"></div>
                </div>
            </>
        )
    }
}