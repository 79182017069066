import {Component} from 'react';

export default class HeaderMenu extends Component 
{
    render()
    {
        return(
            <>                    
                    <header className="header-menu">
                        <hgroup className="menu-title">
                            <h1>Are you redy for Our menu ?</h1>
                            <h2>Is time to eat right now !</h2>
                        </hgroup>
                    </header>
            </>
        )
    }
}