
import { Component } from 'react'

class Product extends  Component
{
    render()
    {
        const path='https://www.food4you.sebastianneumann.fr/img/';
        return (
            <>
            <div className="modulo_producto_uno">
                    <div className="fondo_movil"></div>
                    <div className="imagen">
                        <img src={path+this.props.dataProd.image} alt={this.props.dataProd.name}/>
                    </div>
                    <h3>{this.props.dataProd.name}</h3>
                    <p>{this.props.dataProd.text}</p>
                    <button type="button" onClick={()=>{this.props.navOnepage('reservacion_home')}} name="button">BOOK NOW</button>
            </div>
    
            </>
        )
    }
}

export default Product