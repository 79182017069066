import {Component} from 'react'

class Story extends Component
{
    componentDidMount()
    {
        document.title='Our story | Food 4 you'
    }
    render()
    {
        return (
            <>
                <section id="our-story">
                    <div  className="encabezado-1">
                        <strong>OUR STORY</strong>
                        <h2>KNOW A LITTLE ABOUT FOOD 4 YOU</h2>
                    </div>
                        <p className="paragraph-1">We are in the town center on King Street. Proud to serve a wide range of home cooked true italian dishes.
Covered outdoor seating available.
We look forward to seeing you soon.</p>
                        <p className="paragraph-2 col-2">Using the finest and freshest ingredients our chefs pride themselves on creating mouthwatering dishes. All our pizza's are cooked fresh in our real wood fired oven. This ensures our customers experience the true flavour of Italy. We cater for small and large groups and are open lunchtime until late evening.</p>
                </section>
            </>
        )
    }
}

export default Story