import $ from 'jquery';
import logo from '../assets/img/logo.png'
import TelephoneIcon from '../assets/svg/telefono'
import {NavLink,Link} from 'react-router-dom';
import {Component} from 'react'

import OpenIcon from '../assets/svg/open'
import CloseIcon from '../assets/svg/close'




const openNav=()=>
{
        $('.abrir').fadeOut(300);
        $('.cerrar').fadeIn(300);
        $('#nav_principal ul').css({'left':'0'});
}

const closeNav=()=>
{        
        $('.cerrar').fadeOut(300);
        $('.abrir').fadeIn(300);
        $('#nav_principal ul').css({'left':'-100%'});
}

class NavBar extends Component
{   
   constructor(props)
   {
       super(props)
       this.activateAnimation=this.activateAnimation.bind(this)
       this.animation=this.animation.bind(this)
   }
    componentDidMount()
    {
      this.activateAnimation()
    }

    animation()
    {

        var scroll=$(window).scrollTop();
        if($(window).width()>=600)
        {
        if(scroll>=100)
        {
            $('#header_principal').css({'background':'rgba(255,224,1,1)'});
            $('#header_principal').find('.logo').css({'width':'11vw','top':'1vw','left':'2vw'});
            $('#header_principal').find('.telefono').fadeOut('slow');
            $('#nav_principal ul').css({'top':'2vw','right':'3vw'});
        }
        else
        {
            $('#header_principal').css({'background':'rgba(255,224,1,0)'});
            $('#header_principal').find('.logo').css({'width':'17vw'});
            $('#header_principal').find('.telefono').fadeIn('slow');
            $('#nav_principal ul').css({'top':'6vw','right':'3vw'});
        }
        }
    }

    activateAnimation()
    {
        if($(window).width()>=600)
        {
            $('#header_principal').css({'background':'rgba(255,224,1,0)'});
            $('#header_principal').find('.logo').css({'width':'17vw'});
            $('#header_principal').find('.telefono').fadeIn('slow');
            $('#nav_principal ul').css({'top':'6vw','right':'3vw'});
            $(window).off('scroll');
            $(window).on('scroll',()=>{this.animation()});
        }
        else
        {
            closeNav();
        }
        
    }
    render()
    {

        return (
                    <header id="header_principal">
                        <div className="logo">
                        <Link to="/home" onClick={()=>{this.activateAnimation(); this.props.navOnepage('header_principal')}}>
                            <img src={logo} alt="Logo header"/>
                        </Link>
                        </div>
                        <div className="telefono">
                            <i>
                                <TelephoneIcon/>
                            </i>
                            <span>EXPRESS DELIVERY</span>
                            <span>+44 1753 851418</span>
                        </div>
                        <nav id="nav_principal">
                            <ul>
                                <li>
                                    <NavLink to="/home" activeStyle={{color:'orange'}} onClick={()=>{this.activateAnimation(); this.props.navOnepage('header_principal')}}>
                                          HOME
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/menu" activeStyle={{color:'orange'}} onClick={()=>{this.activateAnimation(); this.props.navOnepage('header_principal')}}>
                                          MENU
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/our-story" activeStyle={{color:'orange'}} onClick={()=>{this.activateAnimation(); this.props.navOnepage('header_principal')}}>
                                          OUR STORY
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contact"  activeStyle={{color:'orange'}} onClick={()=>{this.activateAnimation(); this.props.navOnepage('header_principal')}}>
                                          CONTACT US
                                    </NavLink>
                                </li>
                            </ul>
                            
                            <div className="botones abrir" onClick={openNav}>
                                <OpenIcon/>
                            </div>
                            <div className="botones cerrar" onClick={closeNav}>
                                <CloseIcon/>
                            </div>
                        </nav>
                    </header>
    
        )
    }
}

export default NavBar