import NavBar from "./components/NavBar";
import HomePage from "./components/pages/home";
import Menu from "./components/pages/menu";
import Story from "./components/pages/story";
import Contact from "./components/pages/contact";
import MainFooter from "./components/MainFooter";
import "./assets/css/main.css";
import { Switch, Route } from "react-router-dom";
import { Component } from "react";
import $ from "jquery";

class App extends Component {
  constructor(props) {
    super(props);
    this.navOnepage = this.navOnepage.bind(this);
  }
  navOnepage(idElem) {
    let posicion = $("#" + idElem).position().top;
    let diff = $("#header_principal").height();
    $("html, body").animate({ scrollTop: posicion - diff }, 1000);
  }
  render() {
    return (
      <>
        <NavBar navOnepage={this.navOnepage} />
        <Switch>
          <Route exact path="/">
            <HomePage navOnepage={this.navOnepage} />
          </Route>
          <Route path="/home">
            <HomePage navOnepage={this.navOnepage} />
          </Route>
          <Route path="/menu">
            <Menu />
          </Route>
          <Route path="/our-story">
            <Story />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
        </Switch>

        <MainFooter />
      </>
    );
  }
}

export default App;
