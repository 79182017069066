import Offer1 from '../../assets/img/oferta_uno.jpg'
import Offer2 from '../../assets/img/oferta_dos.jpg'
import Offer3 from '../../assets/img/oferta_tres.jpg'
import $ from 'jquery'
import {Component} from 'react'

class Offers extends Component
{
    componentWillUnmount()
    {
        $(window).off('scroll')
    }

    componentDidMount()
    {
      $(window).on('scroll',()=>{animation()})
    }
    render()
    {


        return(
            <>
                    <section id="ofertas_home">
                        <div className="oferta_uno">
                            <h2>TRY IT TODAY</h2>
                            <h3>MOST POPULAR PASTA</h3>
                            <img src={Offer1} alt="Oferta uno"/>
                        </div>
                        <div className="oferta_dos">
                            <h2>TRY IT TODAY</h2>
                            <h3>MORE FUN MORE TASTE</h3>
                            <img src={Offer2} alt="Oferta dos"/>
                        </div>
                        <div className="oferta_tres">
                            <h2>TRY IT TODAY</h2>
                            <h3>FRESH & CHILI</h3>
                            <img src={Offer3} alt="Oferta tres"/>
                        </div>
                        <div className="clear"></div>
                    </section>
    
            </>
        )
    }
}

export default Offers


// FUNCIONES OFERTAS HOME

  const animation=()=>
  {
      var altoPan=$(window).height();
      var scroll=$(window).scrollTop();
      var alto=$('#ofertas_home').height();
      var posicionTop=$('#ofertas_home').offset().top;
      if($(window).width()>=600)
      {
        var posicionArranque=posicionTop-altoPan+((alto/4)*2);
        if(scroll>=posicionArranque)
        {
          $('.oferta_uno').css({'opacity':'1','left': '0vw','transition':'0.5s ease-in-out','transition-delay':'0s'});
          $('.oferta_dos').css({'opacity':'1','left': '0','transition':'0.5s ease-in-out','transition-delay':'0.1s'});
          $('.oferta_tres').css({'opacity':'1','left': '0','transition':'0.5s ease-in-out','transition-delay':'0.2s'});
        }
        else
        {
          $('.oferta_uno').css({'opacity':'0','left': '-30vw','transition':'.5s cubic-bezier(.46,.12,.62,.94)','transition-delay':'0.2s'});
          $('.oferta_dos').css({'opacity':'0','left': '30vw','transition':'.5s cubic-bezier(.46,.12,.62,.94)','transition-delay':'0.1s'});
          $('.oferta_tres').css({'opacity':'0','left': '30vw','transition':'.5s cubic-bezier(.46,.12,.62,.94)','transition-delay':'0s'});
        }
      }

  }
  
  // FIN FUNCIONES OFERTAS HOME