import $ from 'jquery'
import platoUno from '../../assets/img/plato_uno.png'
import pasta from '../../assets/img/pasta.png'
import hojaUno from '../../assets/img/hoja_uno.png'
import hojaDos from '../../assets/img/hoja_dos.png'
import tomate from '../../assets/img/tomate.png'
import spaguetti from '../../assets/img/spaguetti.png'
import {Component} from 'react'


// FUNCIONES HEADER HOME

    const animation=()=>
    {
        $('#headerHome').animate({'opacity':'1'},500);
        $('#headerHome').find('.cuadroFondo').animate({'width':'100%'},500);
        $('#headerHome').find('.plato_uno').fadeIn(1000)
        $('#headerHome').find('.textos').fadeIn(1000)
    }

  // FIN FUNCIONES HEADER HOME


export default class HeaderHome extends Component
{
   

    componentDidMount()
    {
        animation();
    }

    render()
    {

            return (
                <>
                <header id="headerHome">
                    <div className="cuadroFondo"></div>
                    <div className="plato_uno">
                        <img src={platoUno} alt="Plato de spaguetti"/>
                    </div>
                    <div className="pasta">
                        <img src={pasta} alt="Pasta"/>
                    </div>
                    <div className="hoja_uno">
                        <img src={hojaUno} alt="Hoja uno"/>
                    </div>
                    <div className="hoja_dos">
                        <img src={hojaDos} alt="Hoja dos"/>
                    </div>
                    <div className="tomate">
                        <img src={tomate} alt="Tomate"/>
                    </div>
                    <div className="spaguetti">
                        <img src={spaguetti} alt="Spaguetti"/>
                    </div>
        
                    <div className="textos">
                        <hgroup>
                            <h1>Are You Hungry?</h1>
                            <h2>DON'T WAIT!</h2>
                            <h3>LET START TO ORDER FOOD NOW</h3>
                        </hgroup>
                        <div className="linea"></div>
                        <div className="promo">
                            <span>THIS MONDAY<br/>HAPPY HOURS</span>
                            <span>1+1=3</span>
                        </div>                        
                        <button type="button" name="button" onClick={()=>{this.props.navOnepage('reservacion_home')}}>RESERVE NOW</button>
        
                    </div>
                </header>
                </>
            )
    }
}
