import { Component } from "react"
import $ from 'jquery'

class Contact extends Component
{
    constructor(props)
    {
        super(props)
        
        this.checkForm=this.checkForm.bind(this)
    }
    checkForm()
    {
        let send=true
        
        $('.success-mesagge').fadeOut(0)
        $('.error-mesagge').fadeOut(0)
        $('.input').removeClass('error')
        if($('#name').val()==='')
        {
            $('#name').addClass('error');
            send=false;
        }
        if($('#tel').val()==='')
        {
            $('#tel').addClass('error');
            send=false;
        }
        if($('#message').val()==='')
        {
            $('#message').addClass('error');
            send=false;
        }
        if($('#email').val()==='' || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test($('#email').val())===false)
        {
            $('#email').addClass('error');
            send=false;
        }
        if(send===true)
        {
            document.getElementById('form').reset();
            $('.success-mesagge').fadeIn(0)
        }
        else if(send===false)
        {
            $('.error-mesagge').fadeIn(0)
        }

    }
    componentDidMount()
    {
        document.title='Contact us | Food 4 you'
    }
    render()
    {
        return (
            <>
                <section id="reservacion_home" className="contact">
                        <strong>CONTACT FORM</strong>
                        <h2>SEND US YOUR MESSAGE</h2>
                        <p className="paragraph-1">At Food 4 you's Italian we always strive to give our customers the best dining experience we can. We hope you have had a fantastic time at our establishment and we value your feedback.</p>                        
                        <div class="success-mesagge" >Your message was sent successfully</div>
                        <div class="error-mesagge" >Some errors have occurred</div>
                        <form id="form" method="post">
                            <div className="col">
                                <input type="text"className="input" id="name"  name="name" placeholder="Name"/>
                                <input type="email"className="input" id="email"  name="email" placeholder="Email"/>
                                <input type="tel"className="input" id="tel"  name="tel" placeholder="Phone number"/>
                            </div>
                            <div className="col">
                                <textarea name="message"className="input" id="message"  placeholder="Message"></textarea>
                                <button type="button" onClick={this.checkForm} name="button">SEND MESSAGE</button>
                            </div>
                        </form>
                    </section>
            </>
        )
    }
}

export default Contact