import {Component} from 'react';
import $ from 'jquery'

export default class Reservation extends Component
{
    constructor(props)
    {
        super(props)
        
        this.checkForm=this.checkForm.bind(this)
    }
    checkForm()
    {
        let send=true
        
        $('.success-mesagge').fadeOut(0)
        $('.error-mesagge').fadeOut(0)
        $('.input').removeClass('error')
        if($('#name').val()==='')
        {
            $('#name').addClass('error');
            send=false;
        }
        if($('#date').val()==='')
        {
            $('#date').addClass('error');
            send=false;
        }
        if($('#time').val()==='')
        {
            $('#time').addClass('error');
            send=false;
        }
        if($('#people').val()==='')
        {
            $('#people').addClass('error');
            send=false;
        }
        if($('#email').val()==='' || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test($('#email').val())===false)
        {
            $('#email').addClass('error');
            send=false;
        }
        if(send===true)
        {
            document.getElementById('form').reset();
            $('.success-mesagge').fadeIn(0)
        }
        else if(send===false)
        {
            $('.error-mesagge').fadeIn(0)
        }

    }
    render()
    {
            return (
                <>
                        <section id="reservacion_home">
                            <strong>RESERVATION</strong>
                            <h2>BOOK YOUR TABLE</h2>
                            <div class="success-mesagge" >Your book was made successfully</div>
                            <div class="error-mesagge" >Some errors have occurred</div>
                            <form id="form"  method="post">
                                <div className="col">
                                        <input type="text" className="input" id="name" name="name" placeholder="Name"/>
                                        <input type="email" className="input" id="email" name="email" placeholder="Email"/>
                                        <input type="date" className="input" id="date" name="date" placeholder="Date"/>
                                </div>
                                
                                <div className="col">
                                    <input type="time" className="input" id="time" name="time" placeholder="Time"/>
                                    <input type="number" className="input" id="people" name="people" placeholder="People"/>
                                    <button type="button" onClick={this.checkForm} name="button">FIND A TABLE</button>
                                </div>
                            </form>
                        </section>        
                </>
            )
    }
}

