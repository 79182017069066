import Product from "./ind-product";
import $ from 'jquery'
import {Component} from 'react';
import dataProd from '../../api/products.json';

export default class Products extends Component
{

  componentWillUnmount()
  {
      $(window).off('scroll')
  }

  componentDidMount()
  {
    $(window).on('scroll',()=>{animation()})
  }

  render()
  {
      return (
          <>
              <section id="productos_destacados">
                      <div className="fondo"></div>
                      <h2 id="titulo_productos_destacados">CHOOSE & ENJOY</h2>
                      <p id="texto_productos_destacados">From the beginning anticipation with our appetizers, to the delicious dessert at the end.

Each item on our menu creates a unique dining experience you're sure to enjoy!</p>
                      <div className="productos">
                        {
                          dataProd.map((v,k)=>{
                              return <Product key={k}  dataProd={v} navOnepage={this.props.navOnepage}/>
                          })
                        }
                          
                      </div>
              </section>
          </>
      )

  }
}


// FUNCIONES PRODUCTOS DESTACADOR HOME

   

  const animation=()=>
  {
      var altoPan=$(window).height();
      var scroll=$(window).scrollTop();
      var alto=$('#productos_destacados').height();
      var posicionTop=$('#productos_destacados').offset().top;
      if($(window).width()>=600)      
      {
        var posicionArranque=posicionTop-altoPan+((alto/3)*2);
        if(scroll>=posicionArranque)
        {
          $('.modulo_producto_uno:nth-child(1)').css({'opacity':'1','top': '0','transition':'0.5s ease-in-out','transition-delay':'0s'});
          $('.modulo_producto_uno:nth-child(2)').css({'opacity':'1','top': '0','transition':'0.5s ease-in-out','transition-delay':'0.1s'});
          $('.modulo_producto_uno:nth-child(3)').css({'opacity':'1','top': '0','transition':'0.5s ease-in-out','transition-delay':'0.2s'});
        }
        else
        {
          $('.modulo_producto_uno:nth-child(1)').css({'opacity':'0','top': '30vw','transition':'.5s cubic-bezier(.46,.12,.62,.94)','transition-delay':'0.2s'});
          $('.modulo_producto_uno:nth-child(2)').css({'opacity':'0','top': '30vw','transition':'.5s cubic-bezier(.46,.12,.62,.94)','transition-delay':'0.1s'});
          $('.modulo_producto_uno:nth-child(3)').css({'opacity':'0','top': '30vw','transition':'.5s cubic-bezier(.46,.12,.62,.94)','transition-delay':'0s'});
        }

      }
  
      altoPan=$(window).height();
      scroll=$(window).scrollTop();
      alto=$('#productos_destacados').height();
      posicionTop=$('#productos_destacados').offset().top;
      if($(window).width()>=600)
      {

        var posicionArranque2=posicionTop-altoPan+((alto/7)*2);
        if(scroll>=posicionArranque2)
        {
          $('#texto_productos_destacados').css({'opacity':'1','transition':'0.5s ease-in-out','transition-delay':'0.3s'});
          $('#titulo_productos_destacados').css({'opacity':'1','transition':'0.5s ease-in-out','transition-delay':'0s'});
        }
        else
        {
          $('#texto_productos_destacados').css({'opacity':'0','transition':'.5s cubic-bezier(.46,.12,.62,.94)','transition-delay':'0s'});
          $('#titulo_productos_destacados').css({'opacity':'0','transition':'.5s cubic-bezier(.46,.12,.62,.94)','transition-delay':'0.3s'});
        }
      }

  }
    
  
  // FIN FUNCIONES PRODUCTOS DESTACADOR HOME