
const UbicacionIcon=()=>
{
	return (
			<svg viewBox="0 0 124.634 124.634">
				<path d="M62.713,0.02V0c-0.134,0-0.265,0.008-0.396,0.008S62.055,0,61.921,0v0.02C35.144,0.446,13.565,22.269,13.565,49.148
					c0,36.221,42.526,70.601,48.752,75.486c6.227-4.884,48.752-39.266,48.752-75.486C111.069,22.269,89.491,0.446,62.713,0.02z
					M62.713,70.289c-14.011,0-25.367-11.354-25.367-25.363c0-14.013,11.356-25.369,25.367-25.369s25.368,11.356,25.368,25.369
					C88.081,58.935,76.724,70.289,62.713,70.289z"/>
			</svg>
	)
}

export default UbicacionIcon
